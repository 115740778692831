(function () {
    'use strict';

    angular
        .module('ng-drag-scroll', [])
        .directive('dragScroll', DragScroll);

    DragScroll.$inject = ['$document', '$window', '$parse'];

    /* @ngInject */
    function DragScroll($document, $window, $parse) {
        //Usage:
        //<div drag-scroll>Lorem ipsum dolor sit amet</div>
        var directive = {
            restrict: 'A',
            link: function ($scope, $element, $attributes, vm) {
                var enabled = true;
                var allowedClickOffset = 5;
                var pushed = false;
                var onDragStart = $parse($attributes.onDragStart);
                var onDragEnd = $parse($attributes.onDragEnd);
                var axis = $attributes.axis || false;
                var excludedClasses = $attributes.dragScrollExcludedClasses ? $attributes.dragScrollExcludedClasses.split(',') : [];
                var startClientX;
                var startClientY;
                var lastClientX;
                var lastClientY;
                var is_touch_supported = ('ontouchstart' in window) ? true : false;
                var EVENTS = {
                    POINTER_DOWN: is_touch_supported ? 'touchstart' : 'mousedown',
                    POINTER_UP: is_touch_supported ? 'touchend' : 'mouseup',
                    POINTER_MOVE: is_touch_supported ? 'touchmove' : 'mousemove'
                };
                var dragging = 0;

                $scope.$watch($attributes.dragScroll, function (newValue) {
                    enabled = newValue !== undefined ? newValue : true;
                });

                // Set event listeners
                //$element.on('mousedown', handleMouseDown);
                $element.on(EVENTS.POINTER_DOWN, handleMouseDown);
                //$element.on('touchstart', handleMouseDown);

                // Set destroy listener
                $scope.$on('$destroy', destroy);

                /**
                 * Sets the event listeners for the mouseup and mousedown events
                 */
                function setDragListeners(target) {
                    console.debug('setDragListeners');

                    angular.element(target).on('click', stopClickEventWhenDragging);
                    angular.element($window).on(EVENTS.POINTER_UP, handleMouseUp);
                    angular.element($window).on(EVENTS.POINTER_MOVE, handleMouseMove);
                }

                function stopClickEventWhenDragging(e) {
                    console.debug('click: ');
                    console.debug(e);
                    if (dragging > 2) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    //dragging = 0;
                }

                /**
                 * Removes the event listeners for the mouseup and mousedown events
                 */
                function removeDragListeners() {
                    console.debug('removeDragListeners');
                    angular.element($window).off(EVENTS.POINTER_UP, handleMouseUp);
                    angular.element($window).off(EVENTS.POINTER_MOVE, handleMouseMove);
                }

                /**
                 * Handles mousedown event
                 * @param {object} e MouseDown event
                 */
                function handleMouseDown(e) {
                    console.debug('handleMouseDown: ');
                    console.debug(e);

                    if (enabled) {
                        for (var i = 0; i < excludedClasses.length; i++) {
                            if (angular.element(e.target).hasClass(excludedClasses[i])) {
                                return false;
                            }
                        }

                        $scope.$apply(function () {
                            onDragStart($scope);
                        });

                        // Set mouse drag listeners
                        setDragListeners(e.target);

                        // Set 'pushed' state
                        pushed = true;
                        var coordinates = getCoordinates(e);
                        lastClientX = startClientX = coordinates.clientX;
                        lastClientY = startClientY = coordinates.clientY;

                        clearSelection();

                        //e.preventDefault();
                        e.stopPropagation();

                        dragging = 0;
                    }

                }


                function getCoordinates(e) {
                    if (is_touch_supported) {
                        return e.originalEvent.changedTouches[0];
                    } else {
                        return e;
                    }
                }

                /**
                 * Handles mouseup event
                 * @param {object} e MouseUp event
                 */
                function handleMouseUp(e) {
                    console.debug('handleMouseUp: ');
                    console.debug(e);
                    if (enabled) {
                        var selectable = ('drag-scroll-text' in e.target.attributes);
                        if (selectable) {
                            var coordinates = getCoordinates(e);

                            var withinXConstraints = (coordinates.clientX >= (startClientX - allowedClickOffset) && coordinates.clientX <= (startClientX + allowedClickOffset));
                            var withinYConstraints = (coordinates.clientY >= (startClientY - allowedClickOffset) && coordinates.clientY <= (startClientY + allowedClickOffset));
                        }

                        // Set 'pushed' state
                        pushed = false;

                        // Check if cursor falls within X and Y axis constraints
                        if (selectable && withinXConstraints && withinYConstraints) {
                            // If so, select the text inside the target element
                            selectText(e.target);
                        }

                        $scope.$apply(function () {
                            onDragEnd($scope);
                        });

                        removeDragListeners();

                        //dragging = 0;
                    }

                }

                /**
                 * Handles mousemove event
                 * @param {object} e MouseMove event
                 */
                function handleMouseMove(e) {
                    console.debug('handleMouseMove: ');
                    console.debug(e);
                    if (enabled) {
                        if (pushed) {
                            var coordinates = getCoordinates(e);
                            if (!axis || axis === 'x') {
                                $element[0].scrollLeft -= (-lastClientX + (lastClientX = coordinates.clientX));
                            }
                            if (!axis || axis === 'y') {
                                $element[0].scrollTop -= (-lastClientY + (lastClientY = coordinates.clientY));
                            }
                            e.preventDefault();
                            dragging += 1;
                        }
                    }
                }

                /**
                 * Destroys all the event listeners
                 */
                function destroy() {
                    console.debug('destroy');

                    $element.off(EVENTS.POINTER_DOWN, handleMouseDown);
                    angular.element($window).off(EVENTS.POINTER_UP, handleMouseUp);
                    angular.element($window).off(EVENTS.POINTER_MOVE, handleMouseMove);
                }

                /**
                 * Selects text for a specific element
                 * @param {object} element Selected element
                 */
                function selectText(element) {
                    var range;
                    if ($window.document.selection) {
                        range = $window.document.body.createTextRange();
                        range.moveToElementText(element);
                        range.select();
                    } else if ($window.getSelection) {
                        range = $window.document.createRange();
                        range.selectNode(element);
                        $window.getSelection().addRange(range);
                    }
                }

                /**
                 * Clears text selection
                 */
                function clearSelection() {
                    if ($window.getSelection) {
                        if ($window.getSelection().empty) {  // Chrome
                            $window.getSelection().empty();
                        } else if ($window.getSelection().removeAllRanges) {  // Firefox
                            $window.getSelection().removeAllRanges();
                        }
                    } else if ($document.selection) {  // IE?
                        $document.selection.empty();
                    }
                }


            }
        };
        return directive;

    }

})();
